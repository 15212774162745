import React, { useState, useEffect } from 'react';
import './App.css';
import ReactGA from 'react-ga';
import AdsComponent from './AdsComponent'

const useAnalyticsEventTracker = (category="Çarkıfelek kullanımı") => {
  const eventTracker = (action = "Çevire basıldı", label = "Çark çalıştı") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}

const TRACKING_ID = "G-PWC0S1XTS2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const initialColors = [
  "#3498db", "#2ecc71", "#f1c40f", "#e74c3c",
  "#9b59b6", "#e67e22", "#1abc9c", "#34495e",
  "#d35400", "#8e44ad"
];

function App() {
  const [segmentsData, setSegmentsData] = useState([]);
  const [angle, setAngle] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [newSegmentTitle, setNewSegmentTitle] = useState("");
  const [availableColors, setAvailableColors] = useState(initialColors);

  useEffect(() => {
    // Segment açılarını güncelle ve loga bas
    const segments = segmentsData.length;
    const segmentAngle = 360 / segments;
    const updatedSegments = segmentsData.map((segment, index) => ({
      ...segment,
      angle: segmentAngle,
      currentAngle: (index * segmentAngle) % 360,
    }));
    setSegmentsData(updatedSegments);
  }, [segmentsData.length]);

  const eventTracker = useAnalyticsEventTracker('handleSpin');
  const handleSpin = () => {

    eventTracker();
    if (!isSpinning && segmentsData.length > 0) {
      const segments = segmentsData.length;
      const segmentAngle = 360 / segments;
      const randomSpin = Math.floor(Math.random() * segments);
      const totalAngle = randomSpin * segmentAngle + (360 * 5); // Spin 5 times
      const newAngle = angle + totalAngle;
      setAngle(newAngle);
      setIsSpinning(true);

      setTimeout(() => {
        const finalAngle = newAngle % 360;
        const selectedSegmentIndex = Math.floor((360 - finalAngle + segmentAngle / 2) % 360 / segmentAngle);
        alert(`Kazandığınız ödül: ${segmentsData[selectedSegmentIndex].title}`);

        const updatedSegments = segmentsData.map((segment, index) => ({
          ...segment,
          count: index === selectedSegmentIndex ? (segment.count || 0) + 1 : segment.count,
          currentAngle: ((index * segmentAngle + newAngle) % 360).toFixed(2),
        }));
        setSegmentsData(updatedSegments);
        setIsSpinning(false);
      }, 5000); // Wait for 5 seconds for the spin to finish
    }
  };

  const handleAddSegment = () => {
    if (newSegmentTitle && availableColors.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableColors.length);
      const selectedColor = availableColors[randomIndex];
      const updatedColors = availableColors.filter((_, index) => index !== randomIndex);

      setSegmentsData([...segmentsData, { title: newSegmentTitle , rgbColor: selectedColor, count: 0, currentAngle: 0 }]);
      setNewSegmentTitle("");
      setAvailableColors(updatedColors);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddSegment();
    }
  };

  return (
    <div className="App">
      <div className="split">
        <div className="left">
          <h1>Çarkıfelek</h1>
          <div className="container">
            <div className="wheel-container">
              <div className="pointer"></div>
              <div className="wheel" style={{ transform: `rotate(${angle}deg)` }}>
                {segmentsData.map((segment, index) => (
                  <div
                    key={index}
                    className="segment"
                    data-label={segment.title}
                    style={{
                      transform: `rotate(${index * (360 / segmentsData.length)}deg)`,
                      backgroundColor: segment.rgbColor,
                    }}
                  >
                    <span
                      className="segment-text"
                      style={{ transform: `rotate(${90 - (360 / segmentsData.length) / 2}deg)` }}
                    >
                      {segment.title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <button onClick={handleSpin} disabled={isSpinning || segmentsData.length === 0}>Çevir</button>
          </div>
        </div>
        <div className="right">
          <h2>Yeni Segment Ekle</h2>
          <div className="input-group">
            <input
              type="text"
              placeholder="Başlık"
              value={newSegmentTitle}
              onChange={(e) => setNewSegmentTitle(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button onClick={handleAddSegment} disabled={availableColors.length === 0}>Ekle</button>
          </div>
          <h2>Segment Listesi</h2>
          <ul>
            {segmentsData.map((segment, index) => (
              <li key={index} style={{ color: segment.rgbColor }}>
                {segment.title} - Seçim Sayısı: {segment.count} 
              </li>
            ))}
          </ul>
        </div>
      </div>
       <AdsComponent dataAdSlot='X7XXXXXX5X' />
    </div>
  );
}

export default App;
